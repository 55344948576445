<div class="search desktop-only">
  <div class="search__text">
    <div class="search__text--institute">
      <span><img ngSrc="assets/icons/search.png" alt="header-search-icon" width="32" height="32" /></span>
      <input
        class="desktop-only"
        type="search"
        placeholder="Type or Institute"
        [(ngModel)]="selectedSearchTerm"
        data-test-id="category-header-search-desktop"
        (ngModelChange)="onSearchTerm($event)"
        (focus)="onCategoryFocus()"
      />
      @if(showCategoryDropdown){
      <div class="search__results">
        <ng-container>
          <div class="search__results--title">Type</div>
          @for(type of categories; track type._id){
          <div class="search__results--item"
          data-test-id="category-header-search-result-desktop"
          (click)="onSelectCategory(type)">
            <img ngSrc="assets/icons/search-text.png" alt="header-search-text-icon" width="36" height="36"/>
            <span>{{ type.type }}</span>
          </div>
          }

          <div class="search__results--title">Institute</div>
          @for(institute of institutes; track institute._id){
          <div
            class="search__results--item"
            data-test-id="institute-header-search-result-desktop"
            (click)="onSelectInstitute(institute)"
          >
            <img ngSrc="assets/icons/search-text.png" alt="header-search-text-icon" width="36" height="36"/>
            <span>{{ institute.name }}</span>
          </div>
          }
        </ng-container>
      </div>
      }
    </div>

    <div class="search__text--location ">
      <span><img ngSrc="assets/icons/location.png" alt="header-location-icon" width="32" height="32"/></span>
      <input
        type="search"
        placeholder="Location"
        data-test-id="city-header-search-desktop"
        [(ngModel)]="selectedLocation"
        (ngModelChange)="onSearchLocation($event)"
        (focus)="onLocationFocus()"
      />
      @if(showLocationDropdown){
     @if(allCities$ | async; as allCities){
      <div class="search__location-dropdown">
        @for(city of allCities;track city._id){
        <ng-container>
          <div class="search__results--item"
            data-test-id="city-header-search-result-desktop"
          (click)="onSelectCity(city)">
            <img src="assets/icons/location.png" alt="Location Icon" width="32" height="32"/>
            <span>{{ city.name }}</span>
          </div>
        </ng-container>
        }
      </div>
      } }
    </div>
  </div>
  <!-- <div class="search__action">
      <button class="btn btn-primary">Search</button>
    </div> -->
</div>
