import { CityService } from './../services/city.service';
import { CategoryService } from '@/app/services/category.service';
import { Component, Inject, OnInit } from '@angular/core';
import { InstituteService } from '../services/institute.service';
import { environment } from '@/environments/environment';
import { normalizeTypeName } from '../utils/string';
import City from '../interface/city';
import { AppHeaderComponent } from '../shared/app-header/app-header.component';
import { AppFooterComponent } from '../shared/app-footer/app-footer.component';
import { SearchComponent } from '../search/search.component';
import { RouterLink } from '@angular/router';
import { MetaService } from '../services/meta.service';
import { FaqService } from '../services/faq/faq.service';
import { FaqComponent } from '../shared/faq/faq.component';
import { forkJoin, map, Observable, of } from 'rxjs';
import { AsyncPipe, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { InternalLinksComponent } from '../shared/internal-links/internal-links.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AppHeaderComponent,
    AppFooterComponent,
    SearchComponent,
    RouterLink,
    FaqComponent,
    AsyncPipe,
    InternalLinksComponent,
    NgOptimizedImage
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  city: City[] = [];
  instituteLogos: string[] = [];
  selectedType!: '';
  selectedCity!: '';
  internalLinksItems$: Observable<any[]>;
  popularInstitutes$: Observable<any[]>;
  risingInstitutes$: Observable<any[]>;
  SHIKSHATAMBUCKET_URL = environment.BUCKET_URL;
  showAllCategorySection = true;
  faq$: Observable<any[]>;

  constructor(
    private categoryService: CategoryService,
    private instituteService: InstituteService,
    private metaService: MetaService,
    private faqService: FaqService,
    private cityService: CityService,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  ngOnInit(): void {
    this.popularInstitutes$ = this.instituteService.popularInstitutes$.pipe(
      map((instituteList) => {
        return this.typeChipCalculation(instituteList);
      })
    );

    this.risingInstitutes$ = this.instituteService.risingInstitutes$.pipe(
      map((instituteList) => {
        return this.typeChipCalculation(instituteList);
      })
    );
    this.internalLinks();
    this.setMetaInfo();
    this.getFaq();
  }

  hobbiesSectionData = [
    {
      title: "Coaching's",
      subtitle: 'Best Coaching Class Near Me',
      items: [
        {
          label: 'IIT-JEE COACHING',
          route: `/listing/indore/iit-jee-coaching`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66a2344c79d43f27e72aa7d6',
          },
        },
        {
          label: 'COMPUTER CLASSES',
          route: `/listing/indore/computer-classes`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66405f94c3386ccffe61e0bc',
          },
        },
        {
          label: 'LANGUAGE CLASSES',
          route: `/listing/indore/language-classes`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66405f3bc3386ccffe61e0ae',
          },
        },
        {
          label: 'K-12 COACHING',
          route: `/listing/indore/k-12-coaching`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66405f18c3386ccffe61e0a2',
          },
        },
      ],
    },
    {
      title: 'Hobbies',
      subtitle: 'Dance Class , Music Class And Much More ...',
      items: [
        {
          label: 'DANCE CLASSES',
          route: `/listing/indore/dance-classes`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66405f69c3386ccffe61e0b5',
          },
        },
        {
          label: 'ART CLASSES',
          route: `/listing/indore/art-classes`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66a2350079d43f27e72aa7ff',
          },
        },
        {
          label: 'MUSIC CLASSES',
          route: `/listing/indore/music-classes`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66b0d0eaa8bbd03ca2717530',
          },
        },
        {
          label: 'KARATE CLASSES',
          route: `/listing/indore/karate-classes`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66c0495416e11202e47ed479',
          },
        },
      ],
    },
    {
      title: 'Competitive Exams',
      subtitle: 'UPSC Exam , PSU Exam , Defence Training...',
      items: [
        {
          label: 'PSU EXAMS',
          route: `/listing/indore/psu-exams`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66a75d623799511e6d9e93df',
          },
        },
        {
          label: 'LAW-LLB COACHING',
          route: `/listing/indore/law-llb-coaching`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66cc1c12622eae5afefa7f5a',
          },
        },
        {
          label: 'DEFENCE COACHING',
          route: `/listing/indore/defence-coaching`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '666a9757f2fefe212d2fc1e8',
          },
        },
        {
          label: 'CAT COACHING',
          route: `/listing/indore/cat-coaching`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66405f6fc3386ccffe61e0b8',
          },
        },
      ],
    },
    {
      title: 'Medical Exams',
      subtitle: 'NEET Exam , Nursing Exam , Pharmacy Exam ...',
      items: [
        {
          label: 'NEET COACHING',
          route: `/listing/indore/neet-coaching`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66405f2bc3386ccffe61e0ab',
          },
        },
        {
          label: 'AIAPGET EXAM',
          route: `/listing/indore/aiapget-exam`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66f3ac6a81b313a44dcf886e',
          },
        },
        {
          label: 'PHARMACY COACHING',
          route: `/listing/indore/pharmacy-coaching`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66e279bb3bb16bd2f6095f85',
          },
        },
        {
          label: 'NURSING EXAMS',
          route: `/listing/indore/nursing-exams`,
          queryParams: {
            cityId: '65fa9e7963c2ee5fb9053727',
            typeId: '66e3f0b834929836a1b3bf22',
          },
        },
      ],
    },
  ];

  getTeacherAndExperienceText(institute: any) {
    let minExperience = 0;
    let maxExperience = 0;
    if (institute.teachersExperience?.length > 0) {
      minExperience = Math.min(...institute.teachersExperience);
      maxExperience = Math.max(...institute.teachersExperience);
    }

    return this.instituteService.getTeacherAndExperienceText(
      institute.teachersCount,
      minExperience,
      maxExperience
    );
  }
  setMetaInfo() {
    let logo = '';
    const title =
      'Guru Near Me - Top Coaching Centre Near Me | Find Excellent Tutorials for Academic Success';
    const description =
      'Looking for the best coaching centre near me? Explore nearby coaching centres on Guru Near me that offer excellent tutorials to boost your learning and help you achieve academic success.';

    if (this.dom.location.origin.includes('newui')) {
      logo = `https://newui.gurunearme.com/src/assets/images/logo.png`;
    } else {
      logo = `https://www.gurunearme.com/src/assets/images/logo.png`;
    }

    this.metaService.setMeta(title, description);
    this.metaService.setOgTag(title, description, logo);
    this.metaService.setTwitterCardTag(title, description, logo);
  }

  getFaq() {
    const key: string = 'Home';
    this.faq$ = this.faqService.faq$.pipe(
      map((response: any) => response[key])
    );
  }

  typeChipCalculation(instituteList: any[]) {
    return instituteList.map((instituteObj) => {
      let typeList = instituteObj.typeDetails.map((category: any) => {
        return category.type;
      });
      return {
        ...instituteObj,
        typeChip: instituteObj.typeDetails[0].type,
        moreTypes: {
          count: instituteObj.typeDetails.length - 1,
          title: typeList.join(', '),
        },
      };
    });
  }

  
  internalLinks() {
    this.internalLinksItems$ = forkJoin([
      this.categoryService.allCategories$,
      this.cityService.allCities$,
    ]).pipe(
      map(([categoryResponse, allcities]) => {
        const filteredCities = allcities.filter(city => !city.name.includes('Near Me'));
        return filteredCities.map((city) => {
          return {
            ...city,
            subItems: categoryResponse.data.map((category) => {
              return {
                ...category,
                route: `/listing/${normalizeTypeName(
                  city.name
                )}/${normalizeTypeName(category.type)}`,
                queryParams: {
                  cityId: city._id,
                  typeId: category._id,
                },
              };
            }),
          };
        });
      })
    );
  }
}
