import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component,ElementRef } from '@angular/core';
import { SearchComponent } from '../search/search.component';
import { FormsModule } from '@angular/forms';
import { CategoryService } from '../services/category.service';
import { CityService } from '../services/city.service';
import { Observable, of } from 'rxjs';
import City from '../interface/city';
import { ToastService } from '../services/toast.service';
import { LocationService } from '../services/location.service';

@Component({
  selector: 'app-header-search',
  standalone: true,
  imports: [CommonModule, FormsModule, NgOptimizedImage],
  templateUrl: './header-search.component.html',
  styleUrl: './header-search.component.scss',
})

export class HeaderSearchComponent extends SearchComponent {
 override allCities$: Observable<City[]> = of([]);
 override selectedLocation: string=''
 override selectedCityId: string=''

 constructor(
  categoryService: CategoryService,
  cityService: CityService,
  toastService: ToastService,
  eRef: ElementRef,
  locationService: LocationService
) {
  super(categoryService, cityService, toastService, eRef, locationService);
}

 override ngOnInit(): void {
  super.ngOnInit();
  this.allCities$ = this.cityService.allCities$;
}


  override onSelectCity(city: any): void {
    this.selectedLocation = city.name;
    this.selectedCityId = city._id;
    this.showLocationDropdown = false;
    this.showCategoryDropdown=true
    if(this.selectedCategoryId && this.selectedCityId){
      this.onSearch()
      }
  }
  override onSelectInstitute(institute: any): void {
    this.selectedSearchTerm = institute.name;
    this.selectedInstituteUrlCode = institute.urlCode;
    // Clear selected category so that validation works correctly
    this.selectedCategoryId = '';
    this.showCategoryDropdown = false;
    this.onSearch();
  }

  override onSelectCategory(Category: any): void {
    this.selectedSearchTerm = Category.type
    this.selectedCategoryId = Category._id;

    // Clear selected institute so that validation works correctly
    this.selectedInstituteUrlCode = ''
    this.showCategoryDropdown=false
    this.showLocationDropdown=true
    if(this.selectedCategoryId && this.selectedCityId){
    this.onSearch()
    }
  }
}
