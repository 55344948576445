import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

export const routes: Routes = [
  { 
    path: 'listing/:city/:type', 
    loadComponent: () => import('./listing/listing.component').then(c => c.ListingComponent)
  },
  {
    path: 'institute/:urlCode',
    loadComponent: () => import('./institute-detail/institute-detail.component').then(c => c.InstituteDetailComponent)
  },
  {
    path: 'enquiry',
    loadComponent: () => import('./enquiry/enquiry.component').then(c => c.EnquiryComponent)
  },
  {
    path: 'terms',
    loadComponent: () => import('./shared/terms/terms.component').then(c => c.TermsComponent)
  },
  {
    path: 'privacy',
    loadComponent: () => import('./shared/privacy/privacy.component').then(c => c.PrivacyComponent)
  },
  {
    path: 'report-issue',
    loadComponent: () => import('./report-issue/report-issue.component').then(c => c.ReportIssueComponent)
  },

  {
    path: 'institute/:instituteId/courses/:courseId',
    loadComponent: () => import('./course-detail/course-detail.component').then(c => c.CourseDetailComponent)
  },
  // Blog section URLs start
  {
    path: 'blogs',
    loadComponent: () => import('./blogs-home/blogs-home.component').then(c => c.BlogsHomeComponent)
  },
  {
    path: 'blogs/category/:categorySlug',
    loadComponent: () => import('./blogs-by-category/blogs-by-category.component').then(c => c.BlogsByCategoryComponent)
  },
  {
    path: 'blogs/article/:blogSlug',
    loadComponent: () => import('./blog-details/blog-details.component').then(c => c.BlogDetailsComponent)
  },
  {
    path: 'blogs/search',
    loadComponent: () => import('./blog-search-results/blog-search-results.component').then(c => c.BlogSearchResultsComponent)
  },
  // Blog section URLs end
  /* 
  WARNING: DO NOT ADD NEW ROUTES BELOW THIS SECTION. ADD NEW ROUTES ABOVE THIS LINE!!!
  */
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  { 
    path: '**', 
    loadComponent: () => import('./error/error.component').then(c => c.PageNotFoundComponent),
    data: { description: 'Page not found', title: '404' }
  },
];
