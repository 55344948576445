import { CategoryService, CategoryResponse } from '../services/category.service';
import { Component, ElementRef, HostListener, inject, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CityService } from '../services/city.service';
import { normalizeTypeName } from '../utils/string';
import { ToastService } from '@/app/services/toast.service';
import City from '../interface/city';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { Observable, of } from 'rxjs';
import { LocationService } from '../services/location.service';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [FormsModule, AsyncPipe, NgOptimizedImage],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() showAllCategorySection!: boolean;
  @Input() loading: boolean = false
  @Input() alreadySelectedCategoryId: string
  @Input() alreadySelectedCityId: string
  @Input() longitude!:any
  @Input() latitude!:any

  lat: Number;
  lng: Number;
  showMobileView = false;
  showResults = false;
  showLocationDropdown = false;
  showCategoryDropdown = false;
  categories: any[] = [];
  institutes: any[] = [];
  selectedLocation = 'Indore';
  selectedSearchTerm!: string;
  allCities$: Observable<City[]> = of([]);
  allCategories$: Observable <CategoryResponse>;
  selectedInstituteUrlCode!: string;
  selectedCategoryId!: string;
  selectedCityId = '65fa9e7963c2ee5fb9053727';
  
  constructor(
    private categoryService: CategoryService,
    public cityService: CityService,
    private toastService: ToastService,
    private eRef: ElementRef,
    private locationService: LocationService,
  ) {}

  _router = inject(Router);

  ngOnInit(): void {
    this.categoryService.fetchSearchCategory({institute: true}).then((response) => {
      this.categories = response.data;
      this.institutes = response.institutes;
    });
    if (!this.lat && !this.lng) {
      this.lat=this.latitude;
      this.lng=this.longitude  
    }

  }
  async ngOnChanges(changes: SimpleChanges) {
    this.allCities$ = this.cityService.allCities$;
    this.allCategories$ = this.categoryService.allCategories$;
    if(changes['alreadySelectedCategoryId']){
      this.selectedCategoryId = this.alreadySelectedCategoryId
      this.allCategories$.subscribe(({data: allCategories}) => {
        const found = allCategories.find(item => item._id === this.selectedCategoryId)
        if(found){
          this.selectedSearchTerm = found.type
        }
      })
    }
  
    // Auto-fill City
    if(changes['alreadySelectedCityId']){
      this.selectedCityId = this.alreadySelectedCityId
      this.allCities$.subscribe((allCities) => {
        const found = allCities.find(item => item._id === this.selectedCityId)
        if(found){
          this.selectedLocation = found.name
        }
      })
    }
  }

  @HostListener('document:click', ['$event'])
clickOutside(event: Event) {
  if (!this.eRef.nativeElement.contains(event.target)) {
    this.closeDropDownMenu()
  }
}
  //categorySearch
  onSearchTerm(searchTerm: string) {
    this.selectedSearchTerm = searchTerm;
    if(searchTerm === ''){
      // If user clears the selection, clear the selected category and institute ids.
      this.selectedCategoryId = ''
      this.selectedInstituteUrlCode = ''
    }
    this.categoryService
      .fetchSearchCategory({
        institute: true,
        search: searchTerm,
      })
      .then((response: any) => {
        this.categories = response.data;
        this.institutes = response.institutes;
      })
      .catch((error) => {
        alert('Failed');
      });
  }

  onSelectCategory(Category: any) {
    this.selectedSearchTerm = Category.type
    this.selectedCategoryId = Category._id;

    // Clear selected institute so that validation works correctly
    this.selectedInstituteUrlCode = ''
    this.showCategoryDropdown=false
    this.showLocationDropdown=true
  }
  onSelectInstitute(institute: any) {
    this.selectedSearchTerm = institute.name;
    this.selectedInstituteUrlCode = institute.urlCode;

    // Clear selected category so that validation works correctly
    this.selectedCategoryId = ''
    this.showCategoryDropdown=false;
  }

  onSearchLocation(location: string) {
    if(location === ''){
      // If user clears the selection, clear the selected city id.
      this.selectedCityId = ''
    }
    this.selectedLocation = location
  }
  onSelectCity(city: any) {
    this.selectedLocation = city.name
    this.selectedCityId = city._id;
    this.showLocationDropdown=false
    if(this.selectedLocation=='Near Me'){
      this.fetchNearMe()
    }
  }

  onLocationFocus() {
    this.showCategoryDropdown = false;
    this.showLocationDropdown = true;
  }

  onCategoryFocus() {
    this.showCategoryDropdown = true;
    this.showLocationDropdown = false;
  }
 
  closeDropDownMenu(){
    this.showCategoryDropdown = false;
    this.showLocationDropdown = false;
    this.showMobileView = false;
  }

  onSearch() {
    this.closeDropDownMenu()
    if (!this.selectedCategoryId && !this.selectedInstituteUrlCode) {
      this.toastService.showError('Please select a type or an institute')
    } else if(this.selectedCategoryId && !this.selectedCityId){
      this.toastService.showError('Please select a city or `Near Me`')
    } else if (this.selectedLocation === 'Near Me') {
      this._router.navigateByUrl(
        `/listing/${normalizeTypeName(this.selectedLocation)}/${normalizeTypeName(this.selectedSearchTerm)}?typeId=${this.selectedCategoryId}&latitude=${this.lat}&longitude=${this.lng}`
      );
    } else {
      this._router.navigateByUrl(
        `/listing/${normalizeTypeName(this.selectedLocation)}/${normalizeTypeName(this.selectedSearchTerm)}?cityId=${this.selectedCityId}&typeId=${this.selectedCategoryId}`
      );
    }

    if (this.selectedInstituteUrlCode) {
      this._router.navigateByUrl('institute/' + this.selectedInstituteUrlCode);
    }
  }

    async fetchNearMe() {
    this.loading = true
    if (this.locationService.permissionState === 'granted') {
       this.loading=false
      const { latitude: userLatitude, longitude: userLongitude } =
      this.locationService;
      this.lat = userLatitude;
      this.lng = userLongitude;
    } else {
      if (this.locationService.permissionState === 'denied') {
        this.toastService.showError("You have denied location access to this site")
        this.showLocationDropdown = true;
        this.loading = false
        this.selectedLocation=''
      } else {
        try {
          const position: GeolocationPosition =
            await this.locationService.locateUser();
            this.lat=position.coords.latitude
            this.lng=position.coords.longitude 
          this.loading = false
        } catch (error: any) {
          const errorMessage=typeof error === 'string' ? error : error.message
          this.toastService.showError(errorMessage)
          this.loading = false
          this.selectedLocation=''
          this.showLocationDropdown = true;
        }
      }
    }
  }

  openMobileSearchForm(){
    this.showMobileView = true
    this.showCategoryDropdown = true
  }

}
