import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HeaderSearchComponent } from "../../header-search/header-search.component";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, NgClass, HeaderSearchComponent, NgOptimizedImage],
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss',
})
export class AppHeaderComponent {
 @Input() showHeaderSearch:boolean=false;
  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  constructor() {}

  ngAfterViewInit() {}
  showMenu = false;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
