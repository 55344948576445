<header>
  <div class="branding" [routerLink]="'/'">
    <img ngSrc="assets/images/logo.png" alt="Guru Near Me Logo" width="1216" height="628" priority />
  </div>
  <div class="navigation" [ngClass]="showMenu ? '' : 'desktop-only'">
    @if(showHeaderSearch){
    <app-header-search></app-header-search>
    }
    <a class="navigation__item" [routerLink]="'/'">Home</a>
    <!-- <div class="navigation__item" [routerLink]="'/institutes'">
      Find Institues
    </div> -->
    <a class="navigation__item" [routerLink]="'/blogs'">Blog</a>
    <a class="navigation__item" [routerLink]="'/report-issue'">Report Issue</a>
    <a class="navigation__item" [routerLink]="'/enquiry'">
      <button class="btn btn-secondary">Join as Guru</button>
    </a>
  </div>
  <div class="menu-toggle" (click)="toggleMenu()">
    @if(showMenu){
      <img ngSrc="assets/icons/close-icon.png" alt="close icon" width="36" height="36">
    } @else {
      <img ngSrc="assets/icons/hamburger-icon.png" alt="hamburger icon" width="40" height="32">
    }
  </div>
</header>

